
import { defineComponent, ref, onMounted } from "vue";
import Joi from "joi";
import DlvrInput from "../../../../components/DlvrInput.vue";
import DlvrSelect from "../../../../components/DlvrSelect.vue";
import { merchants } from "@/store";
import { loading, openToast } from "@/composables/ionicControllers";
import { validateFields } from "@/lib/forms";
import {
  IonContent,
  IonPage,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonBackButton,
  IonTitle,
  IonButton,
} from "@ionic/vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    DlvrSelect,
    IonContent,
    IonPage,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonBackButton,
    DlvrInput,
    IonTitle,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const merchantBank = ref<string>("");
    const accountNumber = ref<number>();
    const bankNames = ref<any[]>([]);
    const banksMetaData = ref<any>();
    const isBanksLoading = ref<boolean>(false);

    onMounted(async () => {
      const { getListOfNigerianBanks } = merchants;
      isBanksLoading.value = true;
      const banks = await getListOfNigerianBanks();
      if (banks?.data) {
        isBanksLoading.value = false;
        bankNames.value = banks.data.map((dt: any) => dt.name);
        const banksObj = banks.data.reduce((acc: any, val: any) => {
          acc[val.name.toLowerCase()] = {
            code: val.code,
            currency: val.currency,
            name: val.name,
            slug: val.slug,
            country: val.country,
          };
          return acc;
        }, {});
        banksMetaData.value = banksObj;
      }
    });

    const validationRules = {
      merchantBank: Joi.string().required(),
      accountNumber: Joi.number().required(),
    };

    const accountDetailsSchema = Joi.object(validationRules);

    const saveAccountDetails = async () => {
      const loader = await loading();
      const { error, value } = validateFields(
        accountDetailsSchema,
        () => ({
          merchantBank: merchantBank.value,
          accountNumber: accountNumber.value,
        }),
        false,
      );

      if (error) {
        console.log("error: ", error);
      } else {
        await loader.present();
        const map =
          banksMetaData.value[value.merchantBank?.toLowerCase()];
        const data = {
          bankName: map.name as string,
          bankCode: map.code as string,
          accountNumber: accountNumber.value as number,
        };
        const response: any = await merchants.addNewMerchantAccount(
          data,
        );
        if (response.ok) {
          await loader.dismiss();
          router.back();
          await openToast(response.message);
          return;
        } else {
          await loader.dismiss();
          await openToast(response.message);
          return;
        }
      }
    };

    return {
      merchantBank,
      bankNames,
      accountNumber,
      pageTitle: "Payments",
      saveAccountDetails,
      isBanksLoading,
      validationRules,
    };
  },
});
